import MP from "./MP"


const MPList = ({ matchedMps }) => {

  return (
    <div className="flex flex-col space-y-3">
        <p className='font-bold text-[#2C3388]'>Your local MP(s)</p>
        {matchedMps.map(mp => {
            return <MP lastName={mp.lastName} firstName={mp.firstName} email={mp.emailAddress} /> 
        })}
    </div>
  )
}

export default MPList