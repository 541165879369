import newMps from './assets/data/mp-data-cleaned.json'
import newPostcodes from './assets/data/postcodes-to-electorate.json'
import { useState } from 'react'
import MPList from './components/MPList'

function App() {

  let [matchedMp, setMatchedMp] = useState()
  const [checked, setChecked] = useState(true);
  let [mailTo, setMailTo] = useState("#")

  let anchorStyleDisabled = {
    "pointerEvents": "none",
    "backgroundColor": "#2C3388",
    "opacity": "0.5"
  }

  const postcodeQuery = () => {
    const userInput = document.getElementById("userPostcode").value
    if (userInput === null) {
      alert("Please enter a postcode")
    } else {
      const memberFor = newPostcodes.filter(postcode => {
        return userInput === postcode.postcode
      }
      )
      if (memberFor === undefined) {
        console.log("not found")
      } else {
        return memberFor
      }
    }
  }

  const contains = (mps, electorates) => {
    let mpEmails = []
    electorates.forEach(electorate => {
      mps.forEach(mp => {
        if (mp.electorate === electorate.electorate) {
          mpEmails.push(mp)
        }
      })
    })
    setMatchedMp(mpEmails)
  }

  const getMps = () => {
    const electorates = postcodeQuery()
    if (electorates !==undefined) {
      document.getElementById("sendEmail").removeAttribute("style")
      contains(newMps, electorates)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      getMps()
    }
  }



  const checkMatchedMp = () => {
    if (!matchedMp) {
      return <div></div>
    } else {
      return (
        <MPList matchedMps={matchedMp} />
      )
    }
  }

  const buildEmail = () => {
    const userMessage = document.getElementById("userMessage").value
    const cc = document.getElementById("ccHospitals").checked
    console.log(cc)
    const mpEmails = []
    matchedMp.forEach(mp => {
      mpEmails.push(mp.emailAddress)
    })
    if (cc) {
      const email = "mailto:" + mpEmails.join(",") + "?cc=hospitals@ama.com.au&subject=Clear%20the%20hospital%20logjam&body=" + encodeURIComponent(userMessage)
      setMailTo(email)
    } else {
      const email = "mailto:" + mpEmails.join(",") + "?subject=Clear%20the%20hospital%20logjam&body=" + encodeURIComponent(userMessage)
      setMailTo(email)
    }
  }


  return (
    <div className="App bg-[#F6C43C] flex flex-col space-y-4">
      <h3 className='font-bold text-lg'>Find your local MP</h3>
      <p>Enter your postcode below to find your local MP(s)</p>
      <div className='flex flex-col space-y-3'>
        <div className='flex flex-col space-y-2'>
          <label for="userPostcode" className='font-bold'>Postcode</label>
          <input type="text" className='p-3 rounded-lg' placeholder='Enter postcode' id="userPostcode" onChange={getMps} onKeyPress={(e) => handleKeyPress(e)} />
        </div>

        {checkMatchedMp()}
        <div className='flex flex-col space-y-2'>
          <label for="userMessage" className='font-bold'>Message</label>
          <textarea className='p-3 rounded-lg h-100' id="userMessage" rows="24" cols="48" placeholder='What would you like to say?' />
        </div>
        <div className='flex items-center justify-center space-x-3'>
          <input type="checkbox" name='ccHospitals' id="ccHospitals" defaultChecked={checked} onChange={()=>setChecked(!checked)}/>
          <label for="ccHospitals">Send a copy of your email to the AMA Clear the Hospital Logjam campaign team</label>
        </div>
        <a id="sendEmail" className='p-3 text-white bg-[#2C3388] rounded-full w-full block text-center' href={mailTo} onClick={buildEmail} style={anchorStyleDisabled}>Send message</a>
        <p className='text-center font-medium'>We take your privacy seriously. Read more on our <a href="/privacy-policy" target="_blank" className='border-b-2 border-black'>privacy policy.</a></p>
        <p className='text-center text-sm font-medium'>Data sourced from <a href="https://www.aph.gov.au/" target="_blank" className='border-b-2 border-black'>www.aph.gov.au</a> and <a href="https://electorate.aec.gov.au/" target="_blank" className='border-b-2 border-black'>electorate.aec.gov.au</a></p>
      </div>
    </div>
  );
}

export default App;
